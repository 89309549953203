import { Inject, Injectable, Optional } from '@angular/core';
import { AuthConfig, OAuthErrorEvent, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { delay, filter, from, NEVER, Observable, of, switchMap, timeout } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

import { HashMap } from '@common/angular/interfaces';

import { AuthFacade } from '../../+state';
import {
  AUTH_CONFIG,
  AUTH_CONFIG_APP_API_URL,
  AUTH_CONFIG_APP_ORIGIN,
  AUTH_CONFIG_POST_LOGOUT_URL,
  AUTH_CONFIG_SERVER_URL,
  AUTH_STORAGE,
  AuthServiceConfig,
  AuthStorage,
  AuthUser
} from '../../interfaces';
import { CommonAuthService } from '../auth.service';
import { AUTH_FLOW_CONFIG } from './oauth-config';

@Injectable({ providedIn: 'root' })
export class CommonOAuthService extends CommonAuthService {

  private authInit$: Observable<boolean> | null;
  private logout$: Observable<boolean>;
  private authUser: AuthUser | null;
  private readonly loginTimeOut = 10 * 1000; // 10 seconds

  private get origin(): string {
    return this.authAppOrigin || location.origin;
  }

  constructor(
    private readonly authFacade: AuthFacade,
    private readonly oauthService: OAuthService,
    @Inject(AUTH_STORAGE) private readonly authStorage: AuthStorage,
    @Inject(AUTH_CONFIG) private readonly authConfig: AuthServiceConfig,
    @Inject(AUTH_CONFIG_SERVER_URL) private authServerUrl: string,
    @Optional() @Inject(AUTH_CONFIG_APP_API_URL) private readonly apiUrl: string,
    @Optional() @Inject(AUTH_CONFIG_APP_ORIGIN) private readonly authAppOrigin: string,
    @Optional() @Inject(AUTH_CONFIG_POST_LOGOUT_URL) private readonly postLogoutUrl: string
  ) {
    super();
  }

  init(): Observable<boolean> {
    if (this.authInit$) return this.authInit$;

    console.debug('[Auth: init]', new Date().toString());

    this.setAuthConfig();
    const authInitPromise = this.oauthService.loadDiscoveryDocument();
    this.authInit$ = from(authInitPromise)
      .pipe(
        map(() => true)
      );

    return this.authInit$;
  }

  login(redirectUrl?: string): Observable<AuthUser | null> {
    return this.init().pipe(
      switchMap(() => this.tryLogin()),
      switchMap((isLoggedIn) => {
        if (isLoggedIn) return of(true);

        return this.initLoginFlow(redirectUrl);
      }),
      filter(isLoggedIn => !!isLoggedIn),
      tap(() => {
        this.authUser = this.getAuthUserFromToken();
        this.subscribeToAuthEvents();
        this.setupTokenRefresh();
      }),
      map(() => this.authUser),
      timeout(this.loginTimeOut)
    );
  }

  reLogin(redirectUrl?: string): Observable<AuthUser | null> {
    this.storeReturnUrl(redirectUrl || '');
    return this.init().pipe(
      switchMap(() => {
        if (this.authUser) {
          return from(this.oauthService.revokeTokenAndLogout({ origin: `${this.origin}/${redirectUrl}` }))
            // wait for the redirect to identity server
            .pipe(delay(10000))
        }
        return of(true);
      }),
      switchMap(() => this.login(redirectUrl))
    );
  }

  clearSession(): Observable<boolean> {
    this.authInit$ = null;
    this.oauthService.logOut(true); // silent logout without redirect to clear the cache
    return of(true);
  }

  logout(): Observable<boolean> {
    if (this.logout$) return this.logout$;

    const logoutPromise = this.oauthService.revokeTokenAndLogout({ origin: this.origin });
    this.logout$ = from(logoutPromise).pipe(
      // Make sure we redirect to login page after 2 seconds timeout
      delay(2000),
      switchMap(() => this.initLoginFlow())
    );
    return this.logout$;
  }

  refreshSession(): Observable<boolean> {
    this.oauthService.refreshToken();
    return this.authFacade.isAuthComplete$;
  }

  // Try login from auth callback
  private tryLogin(): Observable<boolean> {
    const loginPromise = this.oauthService.tryLogin();
    return from(loginPromise).pipe(
      map(() => this.hasValidToken())
    )
  }

  private subscribeToAuthEvents(): void {
    this.oauthService.events
      .subscribe((event: OAuthEvent) => {
        if (this.logout$) return;
        if (event instanceof OAuthErrorEvent) {
          console.log('Auth err', event);
          return;
        }
        switch (event.type) {
          case 'token_refreshed':
            return this.onTokenRefreshed();
          case 'token_received':
          case 'silently_refreshed':
            return this.updateAuthState();
          case 'session_terminated':
            console.debug('[Auth: Session terminated]', new Date().toString());
            this.storeReturnUrl(`${location.pathname}${location.search}`);
            return this.logout();
        }
      });
  }

  private updateAuthState(): void {
    const hasValidToken = this.hasValidToken();

    if (!hasValidToken) return;

    const authUser = this.getAuthUserFromToken();

    if (!isEqual(this.authUser, authUser)) {
      this.authUser = this.getAuthUserFromToken();
      this.authFacade.updateAuthUser(this.authUser);
    }

  }

  private setupTokenRefresh(): void {
    if (this.authConfig.sessionRefreshEnabled) {
      this.oauthService.setupAutomaticSilentRefresh();
    }
  }

  private initLoginFlow(redirectUrl?: string): Observable<boolean> {
    const { pathname } = location;
    const { baseAuthRoutePath, loginRoutePath } = this.authConfig;
    const isAuthPath = pathname.startsWith(loginRoutePath) || pathname.startsWith(baseAuthRoutePath);
    const isDefaultUrl = redirectUrl === '/';

    if (!isAuthPath && !isDefaultUrl) {
      this.storeReturnUrl(redirectUrl || '')
    }

    this.oauthService.initLoginFlow();

    return NEVER;
  }

  private storeReturnUrl(url: string): void {
    const { pathname } = location;
    const { baseAuthRoutePath, loginRoutePath } = this.authConfig;
    const isAuthPath = pathname.startsWith(loginRoutePath) || pathname.startsWith(baseAuthRoutePath);
    const storageKey = this.authConfig.storageReturnUrlKey;

    // Ignore if the url is an internal auth redirect path
    if (!storageKey && !isAuthPath) return;

    this.authStorage.setItem(storageKey, url);
  }

  private hasValidToken(): boolean {
    return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
  }

  private setAuthConfig(): void {
    const authFlowConfig = this.getAuthFlowConfig();
    this.oauthService.configure(authFlowConfig);
  }

  private getAuthFlowConfig(): AuthConfig {
    return {
      ...AUTH_FLOW_CONFIG,
      timeoutFactor: AUTH_FLOW_CONFIG.timeoutFactor,
      clientId: this.authConfig.clientId,
      issuer: this.authServerUrl,
      redirectUri: `${this.origin}${this.authConfig.baseAuthRoutePath}`,
      postLogoutRedirectUri: this.postLogoutUrl || this.origin,
      sessionChecksEnabled: this.authConfig.sessionChecksEnabled,
      scope: this.authConfig.scope || AUTH_FLOW_CONFIG.scope,
      customQueryParams: {
        audience: this.apiUrl,
        forceUserLogin: this.authConfig.forceUserLogin
      }
    }
  }

  private onTokenRefreshed(): void {
    const authUser = this.getAuthUserFromToken();
    const isUserChange = this.authUser?.id !== authUser.id;
    const isRoleChange = this.authUser?.role?.toString() !== authUser.role.toString();
    if (isUserChange || isRoleChange) {
      this.authUser = authUser;
      this.authFacade.updateAuthUser(authUser);
    }
  }

  private getAuthUserFromToken(): AuthUser {
    const claims = this.oauthService.getIdentityClaims();
    const accessToken = this.oauthService.getAccessToken();
    const role = this.getTokenPropByKey<string[]>(accessToken, 'role') || [];

    return <AuthUser>{
      id: claims['sub'],
      role
    };
  }

  private getTokenPropByKey<T>(accessToken: string, propKey: string): T {
    const token = this.parseJwtToken(accessToken);
    return token[propKey];
  }

  private parseJwtToken(accessToken: string): HashMap {
    const base64Url = accessToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

}
