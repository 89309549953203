import { createReducer, on } from '@ngrx/store';

import { AuthUser } from '../interfaces';
import { getAuthUserRole } from '../utils';
import { authActions } from './auth.actions';

export const FEATURE_NAME = 'auth';

export interface AuthState {
  isInitialized: boolean; // auth library is initialized
  isAuthInProgress: boolean; // auth process is in progress
  isAuthComplete: boolean; // auth process is completed ( with success or any error )
  isAuthenticated: boolean; // determines if a user has successfully authenticated:
  err: string; // error message if any
  authUser: AuthUser | null; // stores authentication data
}

export const initialState: AuthState = {
  isInitialized: false,
  isAuthInProgress: false,
  isAuthComplete: false,
  isAuthenticated: false,
  err: '',
  authUser: null
};

export const reducer = createReducer(
  { ...initialState },

  on(authActions.init, (state) => ({
    ...state,
    isAuthInProgress: true,
    isInitialized: false
  })),

  on(authActions.initSuccess, (state) => ({
    ...state,
    isAuthInProgress: false,
    isInitialized: true
  })),

  on(authActions.login, authActions.reLogin, (state) => ({
    ...state,
    isAuthInProgress: true
  })),

  on(authActions.loginSuccess, authActions.reLoginSuccess, (state, action) => {
    const { authUser } = action;
    return {
      ...state,
      isAuthInProgress: false,
      isInitialized: true,
      isAuthComplete: true,
      isAuthenticated: true,
      authUser: {
        ...authUser,
        role: getAuthUserRole(authUser)
      }
    }
  }),

  on(authActions.updateAuthUser, (state, action) => {
    let { authUser } = action;
    authUser &&= { ...authUser, role: getAuthUserRole(authUser) }
    return {
      ...state,
      authUser
    }
  }),

  on(authActions.loginFailed, authActions.reLoginFailed, (state, action) => ({
    ...state,
    isAuthInProgress: false,
    isInitialized: true,
    isAuthComplete: true,
    isAuthenticated: false,
    authUser: null,
    err: action.error
  })),

  on(authActions.logout, () => ({
    ...initialState,
    isAuthInProgress: true
  })),

  on(authActions.logoutSuccess, () => ({
    ...initialState,
    isAuthInProgress: false
  })),

  on(authActions.clearSession, (state) => ({
    ...state,
    isAuthInProgress: true
  })),

  on(authActions.clearSessionSuccess, () => ({
    ...initialState
  }))

);

export const getIsAuthenticated = (state: AuthState): boolean => state.isAuthenticated;
export const getIsAuthInProgress = (state: AuthState): boolean => state.isAuthInProgress;
export const getIsAuthComplete = (state: AuthState): boolean => state.isAuthComplete;
export const getUser = (state: AuthState): AuthUser | null => state?.authUser as AuthUser || null;
export const getUserRoles = (state: AuthState): string[] => state.authUser?.role || [];
export const getUserId = (user: AuthUser | null): string | null  => user?.id || null;
