import { createAction, props } from '@ngrx/store';

import { AuthUser } from '../interfaces';

const NAMESPACE = '[Auth]';

const init = createAction(`${NAMESPACE} Init`);
const initSuccess = createAction(`${NAMESPACE} Init Success`);
const initFailed = createAction(`${NAMESPACE} Init Failed`, props<{ err: string; }>());

const login = createAction(`${NAMESPACE} Login`, props<{ redirectUrl: string }>());
const loginSuccess = createAction(`${NAMESPACE} Login Success`, props<{ authUser: AuthUser; }>());
const loginFailed = createAction(`${NAMESPACE} Login Failed`, props<{ error: string; }>());

const reLogin = createAction(`${NAMESPACE} Re-Login`, props<{ redirectUrl: string }>());
const reLoginSuccess = createAction(`${NAMESPACE} Re-Login Success`, props<{ authUser: AuthUser; }>());
const reLoginFailed = createAction(`${NAMESPACE} Re-Login Failed`, props<{ error: string; }>());

const logout = createAction(`${NAMESPACE} Logout`);
const logoutSuccess = createAction(`${NAMESPACE} Logout Success`);

const clearSession = createAction(`${NAMESPACE} Clear Session`);
const clearSessionSuccess = createAction(`${NAMESPACE} Clear Session Success`);
const refreshSession = createAction(`${NAMESPACE} Refresh Session`);

const updateAuthUser = createAction(`${NAMESPACE} Update Auth User`, props<{ authUser: AuthUser | null }>());

export const authActions = {
  init,
  initSuccess,
  initFailed,
  login,
  loginSuccess,
  loginFailed,
  reLogin,
  reLoginSuccess,
  reLoginFailed,
  logout,
  logoutSuccess,
  clearSession,
  clearSessionSuccess,
  refreshSession,
  updateAuthUser
};
